import { createCustomStore, withScopes } from '@emobg/web-utils';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors } from '@/utils';
import { setError as globalSetError } from '@/store/setError';
import { PREBOOKING_ERRORS } from '../const';
const { markAsNonConnected } = backOffice.prebookings;
const { postNonConnectedBooking } = backOffice.bookings;

export const scopes = {
  costAllocations: 'costAllocations',
  markAsNonConnected: 'markAsNonConnected',
  nonConnectedBooking: 'nonConnectedBooking',
};

const prebookings = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, ({
  mutations: {
    setError(state, payload) {
      const { scope, value } = payload;

      if (scope !== scopes.nonConnectedBooking || value.key === PREBOOKING_ERRORS.bookingCannotBeCreated) {
        state[scope].error = value;
      } else {
        globalSetError.bind(this)(state, payload);
      }
    },
    clearErrors,
  },
  actions: {
    async postNonConnectedBooking({ commit }, data) {
      await runAsyncFlow(commit, {
        request: postNonConnectedBooking,
        params: [data],
        scope: scopes.nonConnectedBooking,
      });
    },
    async markAsNonConnected({ commit }, { prebookingUuid, data }) {
      await runAsyncFlow(commit, {
        request: markAsNonConnected,
        params: [prebookingUuid, data],
        scope: scopes.markAsNonConnected,
      });
    },
  },
})));

export default prebookings;
