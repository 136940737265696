export const PREBOOKING_ERRORS = {
  bookingCannotBeCreated: 'booking_cannot_be_created',
};

export const PREBOOKING_MOCK = {
  uuid: '35b0076e-225d-484c-85fd-abfabc2ff99e',
  csOperatorUuid: '2998273d-d7dc-4fcb-9ea6-24c6c070f7de',
  start: '2020-11-18 11:15:00',
  startUtc: '2020-11-18 10:15:00',
  end: '2020-11-18 12:15:00',
  endUtc: '2020-11-18 11:15:00',
  offset: '',
  totalTime: '0 days 1 hours 0 minutes',
  isUnpricedTariff: false,
  tags: [],
  comment: '',
  csBookingUseCase: {
    profile: 'business',
    bookingType: 'carsharing',
    fleet: 'dedicated',
    tripType: 'one_way',
  },
  user: {
    id: '22693',
    uuid: '294b672a-3546-43cd-a68f-981394b82bb1',
    firstName: 'Daniel',
    lastName: 'Cruz',
    email: 'daniel.cruz@kronestest.com',
  },
  vehicleCategory: {
    uuid: '63acdf40-46f4-4c39-90c3-e749114992d2',
    name: 'Medium',
    image: null,
  },
  location: {
    uuid: '2e36fd1a-7603-4419-95ad-b5835698c882',
    name: 'Flughafen Hamburg',
    displayAddress: null,
    address: null,
    gpsLat: null,
    gpsLng: null,
    type: null,
    operatorId: '2998273d-d7dc-4fcb-9ea6-24c6c070f7de',
    city: {
      id: '3a1f42a8-56eb-4d7e-8272-832a50fcdbfb',
      name: 'Hamburg',
      timeZone: 'Europe/Berlin',
    },
  },
  destinationLocation: {
    uuid: 'f798bcfc-3e35-426a-a7e2-796bd88a8351',
    name: 'Flughafen Berlin-Schönefeld',
    displayAddress: null,
    address: null,
    gpsLat: null,
    gpsLng: null,
    type: null,
    operatorId: '2998273d-d7dc-4fcb-9ea6-24c6c070f7de',
    city: {
      id: '2946f9ed-77de-4b60-b805-7587813b5726',
      name: 'Berlin',
      timeZone: 'Europe/Berlin',
    },
  },
  companyCostAllocations: [{ type: 'text', value: 'noexistes', parentUuid: '586818c3-25d7-4ddc-9e76-52596b8be02c' }],
  bookingUuid: 'null',
  objectId: '35b0076e-225d-484c-85fd-abfabc2ff99e',
};
